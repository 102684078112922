import React, { FC, Ref, CSSProperties } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { Div } from 'client/widget-components/basicComponents';
import { HiddenSearchComponentsForEditorComputedStyles } from '../HiddenSearchComponentsForEditorComputedStyles';
import { SearchDropdown } from '../SearchDropdown/SearchDropdown';
import { SearchInput } from '../SearchInput/SearchInput';
import { useCollectionSearch } from '../useCollectionSearch';
import { SEARCH_VALUE_MIN_LENGTH } from '../consts';
import { CollectionsToSearch } from '../types';
import Portal from 'client/widget-components/components/Portal';

interface SearchPopupProps {
    widgetId: string;
    placeholder?: string;
    styles?: {
        iconBtn?: ResponsiveStylesProp;
        input?: ResponsiveStylesProp;
        inputFocus?: ResponsiveStylesProp;
        popup?: ResponsiveStylesProp;
        dropdown?: ResponsiveStylesProp;
        dropdownItemHover?: ResponsiveStylesProp;
    };
    collectionsToSearch: CollectionsToSearch;
    animatedBlockRef?: Ref<HTMLDivElement>;
    animationState: string;
    closeSearchPopup: () => void;
}

export const SearchPopup: FC<SearchPopupProps> = ({
    widgetId,
    placeholder,
    styles,
    collectionsToSearch,
    closeSearchPopup,
    animatedBlockRef,
    animationState,
}) => {
    const {
        inputValue,
        isDropdownOpen,
        isLoading,
        collections,
        onInputChange,
        onInputFocus,
        clearSearchState,
        fetchMore,
    } = useCollectionSearch({ collectionsToSearch });

    const onClosePopup = () => {
        clearSearchState();
        closeSearchPopup();
    };

    return (
        <Portal>
            <Div
                styles={searchPopupDefaultStyles}
                data-grab={`collection-search-widget-portal-${widgetId}`}
            >
                <Div
                    style={transitionOverlayStyles[animationState]}
                    styles={overlayDefaultStyles}
                    onClick={onClosePopup}
                />
                <Div
                    styles={[contentDefaultStyles, styles?.popup]}
                    ref={animatedBlockRef}
                    style={transitionStyles[animationState]}
                    data-grab='collection-search-widget-popup'
                >
                    <SearchInput
                        styles={styles}
                        value={inputValue}
                        placeholder={placeholder}
                        onChange={onInputChange}
                        onFocus={onInputFocus}
                        onClose={onClosePopup}
                    />
                    <SearchDropdown
                        collections={collections}
                        styles={styles}
                        isResultsShown={
                            isDropdownOpen &&
                            inputValue.length >= SEARCH_VALUE_MIN_LENGTH
                        }
                        isLoading={isLoading}
                        fetchMore={fetchMore}
                    />
                </Div>

                <HiddenSearchComponentsForEditorComputedStyles
                    styles={styles}
                />
            </Div>
        </Portal>
    );
};

const searchPopupDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 200,
    },
};

const overlayDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'none',
        background: 'rgba(0, 0, 0, 0.4)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '100vh',
    },
};

const contentDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        position: 'relative',
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        transition: `all 0.2s linear`,
        marginTop: '-200px',
        opacity: 0,
    },
};

const transitionStyles: Record<string, CSSProperties> = {
    entering: { marginTop: 0, opacity: 1 },
    entered: { marginTop: 0, opacity: 1 },
    exiting: { marginTop: '-200px', opacity: 0 },
    exited: { marginTop: '-200px', opacity: 0, height: 0, overflow: 'hidden' },
};

const transitionOverlayStyles: Record<string, CSSProperties> = {
    entering: { display: 'block' },
    entered: { display: 'block' },
    exiting: { display: 'none' },
    exited: { display: 'none' },
};
