import { RuntimeMediaQuery } from '@duda-co/responsive-styles';
import { Div, Img } from 'client/widget-components/basicComponents';
import { useIsVisible } from 'client/widget-components/customHooks/useIsVisible';
import React, { FC } from 'react';
import { SlideData } from '../sliderCommonTypes';

interface Props {
    slidesData: SlideData[];
}

const CacheImagesInView: FC<Props> = ({ slidesData }) => {
    const { elementRef: rootRef, isVisible } = useIsVisible();
    return (
        <div ref={rootRef}>
            <Div styles={containerDefaultStyle}>
                {isVisible &&
                    slidesData.map(
                        (slideData) =>
                            slideData.media?.imgSrc && (
                                <Img
                                    src={slideData.media.imgSrc}
                                    key={slideData.uuid}
                                />
                            )
                    )}
            </Div>
        </div>
    );
};

const containerDefaultStyle = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'none',
    },
};

export default CacheImagesInView;
