import React from 'react';
import { Span } from 'client/widget-components/basicComponents';
import { WarningIcon } from 'client/widget-components/components/icons';
import { useFilterSortStyles } from './utils/filterSortContext';
import {
    mergeResponsiveStyles,
    nestResponsiveStyles,
} from 'client/widget-components/responsive-styles/responsiveStylesService';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

interface TriggerButtonProps {
    toggleMenu: () => void;
    showWarning?: boolean;
    text: string;
    styleWrapperSelector: string;
}
export default function TriggerButton(props: TriggerButtonProps) {
    const { triggerStyle } = useFilterSortStyles();

    return (
        <Span
            data-auto='filter-sort-trigger'
            data-grab={props.styleWrapperSelector}
            styles={[
                buttonDefaultStyles,
                triggerStyle?.button,
                nestResponsiveStyles(
                    mergeResponsiveStyles(triggerStyle?.buttonHover),
                    {
                        pseudoSelector: 'hover',
                    }
                ),
            ]}
            onClick={props.toggleMenu}
        >
            {props.text}
            {props.showWarning && <WarningIcon size={24} />}
        </Span>
    );
}

const buttonDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        width: '100%',
        height: '100%',
        minHeight: '32px',
        border: '1px solid #000000',
        padding: '8px 14px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '400',
        boxSizing: 'border-box',
        minWidth: 'fit-content',
    },
};
