import React from 'react';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import { SlideData } from '../sliderCommonTypes';
import { Placeholder } from 'client/widget-components/components/Placeholder';
import SlideContainer from './SlideContainer';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { ButtonStyles } from 'client/widget-components/CommonModelTypes';
import Button from 'client/widget-components/components/Button';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import { Div } from 'client/widget-components/basicComponents';
import { SlideMedia } from './SlideMedia';
import { nestResponsiveStyles } from 'client/widget-components/responsive-styles/responsiveStylesService';

export interface SlideContentPropsBase extends SlideData {
    imageLayout?: ImageLayout;
    showButtonHoverEffect?: boolean;
    styles?: {
        container?: ResponsiveStylesProp;
        contentContainer?: ResponsiveStylesProp;
        title?: ResponsiveStylesProp;
        desc?: ResponsiveStylesProp;
        button?: ButtonStyles;
        mediaContainer?: ResponsiveStylesProp;
        media?: ResponsiveStylesProp;
        overlay?: ResponsiveStylesProp;
    };
}

export interface SlideContentProps extends SlideContentPropsBase {
    contentAnimationTypeCssClass?: string;
    contentAnimationMode?: 'off' | 'anim-idle' | 'anim-active';
    onContentAnimationCompleted?: () => void;
    outOFViewPort?: boolean;
}

export enum ImageLayout {
    AS_ELEMENT = 'AS_ELEMENT',
    BACKGROUND = 'BACKGROUND',
    AS_CONTENT_ELEMENT = 'AS_CONTENT_ELEMENT',
}

const SlideContent: React.FC<SlideContentProps> = (props) => {
    const {
        styles,
        imageLayout = ImageLayout.BACKGROUND,
        uuid,
        title,
        desc,
        button,
        media,
        linkDomAttributes,
        showButton,
        contentAnimationTypeCssClass,
        contentAnimationMode: _contentAnimationMode,
        imgCssObjectPositionValue,
        showButtonHoverEffect,
        onContentAnimationCompleted,
        outOFViewPort,
    } = props;

    const contentAnimationMode =
        !contentAnimationTypeCssClass || contentAnimationTypeCssClass === 'none'
            ? 'off'
            : _contentAnimationMode ?? 'off';

    if (!(media || title || desc || linkDomAttributes)) {
        return <Placeholder />;
    }

    const mediaContainer = (
        <Div
            data-grab='slide-media-container'
            styles={[
                mediaContainerDefaultStyles,
                imageLayout === ImageLayout.BACKGROUND &&
                    mediaContainerBackgroundDefaultStyle,
                styles?.mediaContainer,
            ]}
        >
            {media && (
                <>
                    <SlideMedia
                        renderPlaceholderOnServer={outOFViewPort}
                        styles={[
                            mediaDefaultStyles,
                            imageLayout === ImageLayout.BACKGROUND &&
                                imageBackgroundStyle,
                            {
                                [RuntimeMediaQuery.COMMON]: {
                                    objectPosition: imgCssObjectPositionValue,
                                },
                            },
                            styles?.media,
                        ]}
                        {...media}
                    />
                    <Div
                        data-grab='slide-overlay'
                        styles={[imageOverlayDefaultStyles, styles?.overlay]}
                    />
                </>
            )}
        </Div>
    );

    return (
        <SlideContainer
            styles={styles?.container}
            linkFunctionalityDomAttributes={
                !showButton && props.linkDomAttributes
            }
            data-auto={`ssr-slide-${uuid}`}
        >
            {imageLayout !== ImageLayout.AS_CONTENT_ELEMENT && mediaContainer}
            <Div
                className={
                    contentAnimationMode === 'anim-active'
                        ? ['animated', contentAnimationTypeCssClass].join(' ')
                        : ''
                }
                data-grab='slideContentContainer'
                styles={[
                    contentContainerDefaultStyles,
                    styles?.contentContainer,
                    {
                        [RuntimeMediaQuery.COMMON]: {
                            visibility:
                                contentAnimationMode === 'anim-idle'
                                    ? 'hidden'
                                    : 'visible',
                        },
                    },
                ]}
                onAnimationEnd={onContentAnimationCompleted}
            >
                {imageLayout === ImageLayout.AS_CONTENT_ELEMENT &&
                    mediaContainer}

                {title && (
                    <Text
                        grabId='title'
                        styles={[titleDefaultStyles, styles?.title]}
                        tag={TextDomTag.h3}
                    >
                        {title}
                    </Text>
                )}
                {desc && (
                    <Text
                        grabId='description'
                        data-auto='desc'
                        styles={[
                            nestResponsiveStyles(descOverrideStyles, {
                                innerSelector: 'p',
                            }),
                            styles?.desc,
                        ]}
                        tag={TextDomTag.div}
                        domAttrs={{
                            dangerouslySetInnerHTML: {
                                __html: desc,
                            },
                        }}
                    />
                )}
                {showButton && button && (
                    <Button
                        styles={{
                            ...(styles?.button ?? ({} as ButtonStyles)),
                            root: [
                                buttonContainerDefaultStyles,
                                styles?.button?.root,
                            ],
                        }}
                        linkFunctionalityDomAttributes={linkDomAttributes}
                        buttonContent={button}
                        showHoverEffect={showButtonHoverEffect}
                    />
                )}
            </Div>
        </SlideContainer>
    );
};

const mediaContainerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        backgroundColor: '#eee',
        overflow: 'hidden',
        position: 'relative',
    },
};

const mediaContainerBackgroundDefaultStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        top: 0,
        right: 0,
    },
};

const imageBackgroundStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        height: '100%',
    },
};

const mediaDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        objectFit: 'cover',
        objectPosition: 'center',
        display: 'block',
        width: '100%',
        height: '100%',
    },
};

const imageOverlayDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
};

const contentElementDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 0,
    },
};

const titleDefaultStyles: ResponsiveStylesStructured[] = [
    contentElementDefaultStyles,
    {
        [RuntimeMediaQuery.COMMON]: {
            marginBlockEnd: 8,
        },
    },
];

const descOverrideStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        marginBlock: 0,
    },
};

const buttonContainerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        minWidth: 150,
        width: 'auto',
        paddingInlineStart: 20,
        paddingInlineEnd: 20,
        margin: 0,
        marginBlockStart: 24,
    },
};

const contentContainerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        visibility: 'visible',
    },
};

export default withErrorBoundary({
    Comp: SlideContent,
    componentName: 'SlideContent',
});
