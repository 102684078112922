function isObject(item: any): item is Record<string, any> {
    return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeepPure<T extends Record<string, any>>(
    object1: T,
    object2: T
): T {
    const newObject = { ...object1 };
    (Object.keys(object2) as Array<keyof T>).forEach((key) => {
        if (isObject(object1[key]) && isObject(object2[key])) {
            newObject[key] = mergeDeepPure(object1[key], object2[key]);
        } else {
            newObject[key] = object2[key];
        }
    });

    return newObject;
}
