import React, { useEffect, useState } from 'react';
import { RuntimeWidget } from 'client/types';
import { FilterSortExtensionData } from './components/utils/types';
import FilterSortMenu from './components/FilterSortMenu';
import { useEditorContext } from 'client/infra/renderers/editorState';
import { hasProductGalleryOnPage } from './components/utils';
import { Div } from 'client/widget-components/basicComponents';
import TriggerButton from './components/TriggerButton';
import { FilterSortContext } from './components/utils/filterSortContext';
import { WidgetStyles } from './components/utils/types/styles';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

export interface RuntimeFilterSortProps extends FilterSortExtensionData {
    widgetId: string;
    _styles?: WidgetStyles;
}

export const RuntimeFilterSort: RuntimeWidget<RuntimeFilterSortProps> = ({
    hasNativeStore,
    widgetId,
    showWidgetMenuContent = false,
    ...props
}: RuntimeFilterSortProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const { isInEditor } = useEditorContext();

    useEffect(() => {
        setShowWarning(!hasNativeStore || !hasProductGalleryOnPage());
    }, []);

    const toggleMenu = () => {
        if (!isInEditor) {
            setIsMenuOpen((open) => !open);
        }
    };
    const setIsOpen = (open: boolean) => {
        setIsMenuOpen(open);
    };
    const widgetContext = {
        styles: props._styles || {},
        showWidgetMenuContent,
    };
    return (
        <FilterSortContext.Provider value={widgetContext}>
            <Div styles={wrapperDefaultStyles}>
                <TriggerButton
                    toggleMenu={toggleMenu}
                    showWarning={showWarning}
                    text={props.buttonText}
                    styleWrapperSelector={`filter-sort-trigger-${widgetId}`}
                />
            </Div>
            <FilterSortMenu
                isOpen={isMenuOpen || showWidgetMenuContent}
                styleWrapperSelector={`filter-sort-menu-${widgetId}`}
                setIsMenuOpen={setIsOpen}
                widgetId={widgetId}
                {...props}
            />
        </FilterSortContext.Provider>
    );
};

const wrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        height: '100%',
        minWidth: 'fit-content',
    },
};

export default RuntimeFilterSort;
