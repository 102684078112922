import React, { FC } from 'react';
import SliderLayoutView from './SliderLayoutView';
import { SliderLayoutProps } from '../sliderCommonTypes';
import pick from 'lodash/pick';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

const viewComputedPropsToPick = [
    'styles',
    'animationType',
    'imageLayout',
    'paginationType',
    'slideAnimationDirection',
    'arrowStyle',
] as const;

export const SliderLayoutViewForComputedDesign: FC<
    Pick<SliderLayoutProps, (typeof viewComputedPropsToPick)[number]>
> = (props) => {
    const relevantProps = pick(props, viewComputedPropsToPick);
    const { styles } = relevantProps;

    return (
        <SliderLayoutView
            slidesData={[
                {
                    showButton: true,
                    button: {
                        text: 'a',
                    },
                    uuid: 'a',
                    title: 'a',
                    desc: 'a',
                    media: {
                        imgSrc: 'https://du-cdn.cdn-website.com/duda_website/images/home/hp_performance.png?v=17',
                        alt: 'a',
                    },
                    linkDomAttributes: {
                        type: 'url',
                        href: 'https://www.duda.co/',
                    },
                },
            ]}
            {...relevantProps}
            styles={{
                ...styles,
                container: [styles.container, containerDefaultStyles],
            }}
            slotsInFrame={1}
        />
    );
};

const containerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'none',
    },
};
