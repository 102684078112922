import React from 'react';
import { FilterableField, FilterTypes } from '../utils/types';
import FilterItem from './FilterItem';

interface FilterListProps {
    values: Array<FilterableField>;
    currentSelection: {
        [filterName: string]: FilterableField;
    };
    onChange: (filterValue: FilterableField) => void;
}
function FiltersList({ values, currentSelection, onChange }: FilterListProps) {
    return (
        <>
            {values.map((filterField) => {
                if (filterField.disabled) {
                    return null;
                }
                const filterId = filterField.fieldId;
                const filterType = filterField.filterType || FilterTypes.ONE_OF;
                const filterSelectedValues =
                    currentSelection[filterId]?.selectedValues || undefined;
                return (
                    <FilterItem
                        key={filterId}
                        item={filterField}
                        type={filterType}
                        currentValues={filterSelectedValues}
                        onChange={(selectedValues) => {
                            onChange({
                                ...filterField,
                                filterType,
                                selectedValues,
                            });
                        }}
                    />
                );
            })}
        </>
    );
}

export default FiltersList;
