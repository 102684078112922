import React, { useMemo } from 'react';
import { localization } from '@duda-co/host-services';
import { CloseIcon } from 'client/widget-components/components/icons';
import SortItemsList from './sort';
import { FilterSortExtensionData } from './utils/types';
import FiltersList from './filter';
import { nestResponsiveStyles } from 'client/widget-components/responsive-styles/responsiveStylesService';
import useFilterSortLogic, {
    FilterSortLogicResult,
} from './useFilterSortLogic';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { Div, Label, Span } from 'client/widget-components/basicComponents';
import { useFilterSortStyles } from './utils/filterSortContext';
import Portal from 'client/widget-components/components/Portal';

const { str } = localization;

interface FilterSortMenuProps
    extends Omit<FilterSortExtensionData, 'hasNativeStore' | 'widgetId'> {
    isOpen: boolean;
    styleWrapperSelector: string;
    widgetId: string;
    setIsMenuOpen: (v: boolean) => void;
}
function FilterSortMenu(props: FilterSortMenuProps) {
    const {
        filterableFields = [],
        sortableFields,
        collectionValue,
        isSortSectionHidden,
        isOpen,
    } = props;

    const filterSortLogic = useFilterSortLogic(collectionValue, {
        isSortSectionHidden,
        sortableFields,
    });

    const allOptionsAreDisabled = useMemo(() => {
        const isContentHidden =
            props.isFilterSectionHidden && props.isSortSectionHidden;
        if (isContentHidden) {
            return true;
        }
        const enabledItem = [
            ...(props.isFilterSectionHidden ? [] : filterableFields),
            ...(props.isSortSectionHidden ? [] : sortableFields),
        ].some((item) => !item.disabled);
        return !enabledItem;
    }, [filterableFields, sortableFields]);

    if (filterSortLogic && isOpen && !allOptionsAreDisabled) {
        return (
            <Portal>
                <FilterSortMenuReady
                    filterSortLogic={filterSortLogic}
                    props={props}
                />
            </Portal>
        );
    }
    return null;
}

function FilterSortMenuReady({
    props,
    filterSortLogic,
}: {
    props: FilterSortMenuProps;
    filterSortLogic: FilterSortLogicResult;
}) {
    const { menuStyle, textStyle } = useFilterSortStyles();
    const {
        sortableFields,
        filterableFields,
        buttonText,
        isFilterSectionHidden,
        styleWrapperSelector,
        setIsMenuOpen,
    } = props;

    const {
        currentSelection,
        onChangeFilter,
        showSortSection,
        onChangeSort,
        clearCollectionValues,
    } = filterSortLogic;

    return (
        <Div
            styles={[wrapperDefaultStyles, menuStyle?.wrapper]}
            data-auto='filter-sort-floating-menu'
            data-grab={styleWrapperSelector}
        >
            <Div styles={topSectionDefaultStyles}>
                <Label
                    styles={[titleDefaultStyles, textStyle?.displayName]}
                    data-grab='filter-sort-menu-displayName'
                >
                    {buttonText}
                </Label>
                <CloseIcon
                    styles={[closeBtnDefaultStyles, menuStyle?.closeIcon]}
                    dataGrab='filter-sort-menu-closeIcon'
                    onClick={() => setIsMenuOpen(false)}
                />
            </Div>
            <Div
                styles={clearAllDefaultStyles}
                onClick={clearCollectionValues}
                data-auto='clear-filters'
            >
                <Span
                    styles={[textStyle?.displayName]}
                    data-grab='filter-sort-menu-displayName'
                >
                    {str('widget.filtersort.clear-all')}
                </Span>
                <CloseIcon size={8} styles={[textStyle?.displayName]} />
            </Div>
            <Div
                styles={[
                    contentDefaultStyles,
                    contentScrollbarDefaultStyles,
                    menuStyle?.list,
                ]}
                data-grab='filter-sort-menu-list'
            >
                {!isFilterSectionHidden && (
                    <FiltersList
                        values={filterableFields}
                        currentSelection={currentSelection.filters}
                        onChange={onChangeFilter}
                    />
                )}
                {showSortSection && (
                    <SortItemsList
                        name={str('widget.filtersort.sort-by.title')}
                        values={sortableFields}
                        onChange={onChangeSort}
                        currentSelection={{
                            sortDirection:
                                currentSelection.sortDirection || 'asc',
                            fieldId: currentSelection.sortBy || '',
                        }}
                    />
                )}
            </Div>
        </Div>
    );
}

const wrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        cursor: 'auto',
        height: '100%',
        position: 'fixed',
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#ffffff',
        color: '#000000',
        zIndex: 999,
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid #000',
        width: '418px',
        padding: '40px',
        maxWidth: '84vw',
    },
};
const topSectionDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBlockEnd: '26px',
    },
};

const titleDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '29px',
        color: '#000',
    },
};
const clearAllDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        color: '#000',
        fontFamily: 'Syne',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        textTransform: 'uppercase',
        marginBlock: '50px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer',
    },
};

const closeBtnDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        cursor: 'pointer',
        color: '#333',
        display: 'flex',
    },
};

const contentDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
};

const contentScrollbarDefaultStyles: ResponsiveStylesStructured =
    nestResponsiveStyles(
        {
            [RuntimeMediaQuery.COMMON]: {
                width: '10px',
                backgroundColor: 'transparent',
            },
        },
        {
            innerSelector: '&::-webkit-scrollbar',
        }
    );

export default FilterSortMenu;
