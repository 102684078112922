import React from 'react';
import { Accordion } from 'client/widget-components';
import { useFilterSortContext } from './utils/filterSortContext';
import { Span } from 'client/widget-components/basicComponents';
import { nestResponsiveStyles } from 'client/widget-components/responsive-styles/responsiveStylesService';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

export interface MenuItemProps {
    title: string;
    children: React.ReactNode;
}

export default function MenuItem({ title, children }: MenuItemProps) {
    const { styles, showWidgetMenuContent } = useFilterSortContext();
    const { menuStyle, textStyle } = styles;

    return (
        <>
            <Accordion
                title={title}
                styles={{ header: textStyle?.title }}
                isAccordionOpen={showWidgetMenuContent}
                data-grab='filter-sort-menu-item-title'
            >
                {children}
            </Accordion>
            <Span
                styles={[
                    dividerDefaultStyles,
                    menuStyle?.divider,
                    nestResponsiveStyles(
                        {
                            [RuntimeMediaQuery.COMMON]: {
                                display: 'none',
                            },
                        },
                        { innerSelector: '&:last-child' }
                    ),
                ]}
                data-grab='filter-sort-menu-divider'
            />
        </>
    );
}

const dividerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        backgroundColor: '#e1e1e1',
        marginBlock: '24px',
        display: 'block',
        height: '2px',
    },
};
