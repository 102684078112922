import React from 'react';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { Svg } from 'client/widget-components/basicComponents';

export default function ({
    styles,
    size = 15,
    onClick,
    dataGrab,
}: {
    styles?: ResponsiveStylesProp;
    size?: number;
    onClick?: () => void;
    dataGrab?: string;
}) {
    return (
        <Svg
            styles={styles}
            width={size}
            height={size}
            onClick={onClick}
            data-grab={dataGrab}
            viewBox='0 0 16 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M0.604736 0.5L14.6047 14.5'
                stroke='currentColor'
                strokeWidth='1.33333'
            />
            <path
                d='M14.6047 0.5L0.604736 14.5'
                stroke='currentColor'
                strokeWidth='1.33333'
            />
        </Svg>
    );
}
