import React from 'react';
import { WidgetStyles } from './types/styles';

export const FilterSortContext = React.createContext<{
    styles: WidgetStyles;
    showWidgetMenuContent: boolean;
}>({
    styles: {},
    showWidgetMenuContent: false,
});

export function useFilterSortStyles() {
    return useFilterSortContext().styles;
}
export function useFilterSortContext() {
    return React.useContext(FilterSortContext);
}
