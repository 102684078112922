import { Checkbox } from 'client/widget-components';
import React from 'react';
import { FilterItemProps, FilterTypes } from '../utils/types';
import { useFilterSortStyles } from '../utils/filterSortContext';

function SelectionFilterItem(props: FilterItemProps<FilterTypes.ONE_OF>) {
    const { item, currentValues, onChange } = props;
    const { inputsStyle } = useFilterSortStyles();

    const selectedFilterValues = new Set<string | number>(currentValues || []);
    function onSelectValue(
        filterIdentifier: string | number,
        selected: boolean
    ) {
        selectedFilterValues[selected ? 'add' : 'delete'](filterIdentifier);
        onChange([...selectedFilterValues]);
    }

    return (
        <>
            {Object.entries(item.filtersData).map(([name, identifier]) => {
                const isChecked = selectedFilterValues.has(identifier);
                const key = `${item.fieldId}_${name}`;
                return (
                    <Checkbox
                        key={key}
                        data-auto={key}
                        labelDataGrab='filterItem-input-label'
                        iconDataGrab='filterItem-input-icon-wrapper'
                        checked={isChecked}
                        onChange={(isChecked: boolean) => {
                            onSelectValue(identifier, isChecked);
                        }}
                        label={name}
                        inputStyles={inputsStyle?.input}
                        inputIconStyles={inputsStyle?.inputIcon}
                        labelStyles={inputsStyle?.inputLabel}
                    />
                );
            })}
        </>
    );
}

export default SelectionFilterItem;
