import React, { useEffect, useState } from 'react';
import { ArrowIcon } from '../icons';
import { Div, Label } from 'client/widget-components/basicComponents';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

export interface AccordionProps {
    title: string;
    children: React.ReactNode;
    isAccordionOpen?: boolean;
    styles?: {
        container?: ResponsiveStylesProp;
        header?: ResponsiveStylesProp;
    };
}
function Accordion({
    title,
    children,
    styles,
    isAccordionOpen = false,
    ...rest
}: AccordionProps) {
    const [isOpen, setIsOpen] = useState(isAccordionOpen);

    useEffect(() => {
        setIsOpen(isAccordionOpen);
    }, [isAccordionOpen]);
    const toggleAccordion = () => {
        setIsOpen((open) => !open);
    };
    return (
        <Div styles={[wrapperDefaultStyles, styles?.container]} {...rest}>
            <Div
                styles={[headerWrapperStyles, styles?.header]}
                onClick={toggleAccordion}
                data-auto='accordion-trigger'
            >
                <Label styles={labelDefaultStyles}>{title}</Label>
                <Div styles={getArrowStyles(isOpen)}>
                    <ArrowIcon />
                </Div>
            </Div>
            <Div
                styles={listDefaultStyles}
                style={{ display: isOpen ? 'flex' : 'none' }}
                data-auto='accordion-list'
            >
                {children}
            </Div>
        </Div>
    );
}

const headerWrapperStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000',
        gap: '8px',
        lineBreak: 'anywhere',
    },
};

const wrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
    },
};
const labelDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        maxWidth: '90%',
        cursor: 'pointer',
    },
};
const listDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        marginBlockStart: '16px',
        flexDirection: 'column',
        gap: '8px',
        display: 'flex',
    },
};
function getArrowStyles(isOpen: boolean): ResponsiveStylesStructured {
    return {
        [RuntimeMediaQuery.COMMON]: {
            display: 'grid',
            alignItems: 'center',
            transform: `rotate(${isOpen ? '0' : '180deg'})`,
        },
    };
}

export default Accordion;
