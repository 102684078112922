import React, { memo } from 'react';
import { SortableField } from '../utils/types';
import { RadioButton } from 'client/widget-components';
import MenuItem from '../MenuItem';
import { useFilterSortStyles } from '../utils/filterSortContext';

interface SortItemsListProps {
    name: string;
    values: SortableField[];
    onChange: (selectedSort: SortableField) => void;
    currentSelection: Omit<SortableField, 'fieldDisplayName'>;
}

function SortItemsList({
    name,
    values,
    currentSelection,
    onChange,
}: SortItemsListProps) {
    const { inputsStyle } = useFilterSortStyles();

    return (
        <MenuItem title={name}>
            {values.map((sortField) => {
                if (sortField.disabled) {
                    return null;
                }
                const sortKey = `${sortField.fieldId}_${sortField.sortDirection}`;
                const selectedValue = `${currentSelection.fieldId}_${currentSelection.sortDirection}`;
                return (
                    <RadioButton
                        key={sortKey}
                        value={sortKey}
                        selectedValue={selectedValue}
                        label={sortField.fieldDisplayName}
                        onChange={() => onChange(sortField)}
                        labelStyles={inputsStyle?.inputLabel}
                        inputStyles={inputsStyle?.input}
                        inputIconStyles={inputsStyle?.inputIcon}
                        data-auto={sortKey}
                        labelDataGrab='filterItem-input-label'
                        iconDataGrab='filterItem-input-icon-wrapper'
                    />
                );
            })}
        </MenuItem>
    );
}
export default memo(SortItemsList);
