import React, { useCallback, useEffect, useState } from 'react';
import { RangeSliderProps } from './types';
import { RangeInput } from './RangeInput';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { Div, Label } from 'client/widget-components/basicComponents';
import debounce from 'lodash/debounce';
import { mergeResponsiveStyles } from 'client/widget-components/responsive-styles/responsiveStylesService';

const RangeSlider = (props: RangeSliderProps) => {
    const { minRange = 0, currentValues } = props;
    const [rangeValues, setRangeValues] = useState({
        start: currentValues?.start || props.min,
        end: currentValues?.end || props.max,
    });
    const onChange = useCallback(debounce(props.onChange, 300), []);

    useEffect(() => {
        setRangeValues({
            start: currentValues?.start || props.min,
            end: currentValues?.end || props.max,
        });
    }, [currentValues]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;
        const min = parseInt(event.target.min, 10);
        const max = parseInt(event.target.max, 10);
        const value = event.target.valueAsNumber;

        const updatedValues = { ...rangeValues, [name]: value };
        const pointsDistance = Math.abs(
            rangeValues[name === 'start' ? 'end' : 'start'] - value
        );
        if (minRange > pointsDistance) {
            const diffRange = minRange - pointsDistance;
            if (name === 'start') {
                updatedValues.end = Math.min(rangeValues.end + diffRange, max);
            } else if (name === 'end') {
                updatedValues.start = Math.max(
                    rangeValues.start - diffRange,
                    min
                );
            }
        }
        setRangeValues(updatedValues);
        onChange(updatedValues);
    };
    const { thumbStyles, thumbSize } = getThumbStyles({
        thumbStyles: props.thumbStyles,
        trackStyles: props.trackStyles,
    });
    return (
        <Div data-auto='range-slider'>
            <Div
                styles={[valuesWrapperDefaultStyles, props.labelsWrapperStyles]}
            >
                <Label styles={props.labelStyles} data-auto='min-input-label'>
                    <span>{rangeValues.start}</span>
                    {props.sign && <span>{props.sign}</span>}
                </Label>
                <Label styles={props.labelStyles} data-auto='max-input-label'>
                    <span>{rangeValues.end}</span>
                    {props.sign && <span>{props.sign}</span>}
                </Label>
            </Div>
            <Div styles={getInputWrapperStyles(thumbSize)}>
                <Div
                    styles={[trackDefaultStyles, props.trackStyles]}
                    data-grab={props.styleSelector}
                >
                    {!props.hideRangeTrack && (
                        <Div
                            styles={getRangeTrackStyles({
                                ...rangeValues,
                                min: props.min,
                                max: props.max,
                            })}
                        />
                    )}
                </Div>
                <RangeInput
                    name='start'
                    min={props.min}
                    max={props.max}
                    value={rangeValues.start}
                    onChange={handleChange}
                    data-auto='range-slider-start-input'
                    thumbStyles={thumbStyles}
                />
                <RangeInput
                    name='end'
                    min={props.min}
                    max={props.max}
                    value={rangeValues.end}
                    onChange={handleChange}
                    data-auto='range-slider-end-input'
                    thumbStyles={thumbStyles}
                />
            </Div>
        </Div>
    );
};

function getThumbStyles(
    props: Pick<RangeSliderProps, 'trackStyles' | 'thumbStyles'>
) {
    const trackStyles = mergeResponsiveStyles(props.trackStyles);
    const trackHeight = parseInt(
        (
            trackStyles.common?.height ||
            trackStyles.desktop?.height ||
            0
        ).toString()
    );
    const thumbSize = `${Math.max(trackHeight * 2, 8)}px`;
    const dimensions = {
        height: thumbSize,
        width: thumbSize,
    };
    const mergedThumbStyles = mergeResponsiveStyles([
        props.trackStyles,
        props.thumbStyles,
        {
            [RuntimeMediaQuery.COMMON]: dimensions,
            [RuntimeMediaQuery.DESKTOP]: dimensions,
            [RuntimeMediaQuery.TABLET]: dimensions,
        },
    ]);
    return {
        thumbStyles: mergedThumbStyles,
        thumbSize,
    };
}
function getInputWrapperStyles(
    thumbHeight: string
): ResponsiveStylesStructured {
    return {
        [RuntimeMediaQuery.COMMON]: {
            display: 'flex',
            alignItems: 'center',
            minHeight: thumbHeight,
            position: 'relative',
        },
    };
}
const valuesWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBlockEnd: '12px',
    },
};

function getRangeTrackStyles({
    start,
    end,
    min,
    max,
}: Record<string, number>): ResponsiveStylesStructured {
    const totalDistance = max - min;
    const startDistance = ((start - min) / totalDistance) * 100;
    const endDistance = ((end - start) / totalDistance) * 100;
    return {
        [RuntimeMediaQuery.COMMON]: {
            backgroundColor: '#000000',
            opacity: '0.6',
            position: 'absolute',
            height: '100%',
            borderRadius: '24px',
            left: `${startDistance}%`,
            width: `${endDistance}%`,
        },
    };
}

const trackDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        backgroundColor: '#000',
        width: '100%',
        height: '2px',
        position: 'relative',
    },
};

export default RangeSlider;
export { type RangeSliderProps };
