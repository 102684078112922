import React, { InputHTMLAttributes } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

import { Input } from 'client/widget-components/basicComponents/Input/Input';
import {
    nestResponsiveStyles,
    ResponsiveStylesProp,
} from 'client/widget-components/responsive-styles/responsiveStylesService';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    styles?: ResponsiveStylesProp;
    thumbStyles?: ResponsiveStylesStructured;
}
export function RangeInput(props: Props) {
    return (
        <Input
            type='range'
            styles={[
                defaultInputStyles,
                nestResponsiveStyles(vendorDefaultStyles, {
                    innerSelector: '&::-webkit-slider-thumb',
                }),
                nestResponsiveStyles(vendorDefaultStyles, {
                    innerSelector: '&::-moz-range-thumb',
                }),
                nestResponsiveStyles(props.thumbStyles, {
                    innerSelector: '&::-webkit-slider-thumb',
                }),
                nestResponsiveStyles(props.thumbStyles, {
                    innerSelector: '&::-moz-range-thumb',
                }),
            ]}
            {...props}
        />
    );
}

const defaultInputStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 0,
        height: 0,
        width: '100%',
        position: 'absolute',
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        pointerEvents: 'none',
        cursor: 'pointer',
    },
};

const vendorDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        WebkitAppearance: 'none',
        backgroundColor: '#000',
        pointerEvents: 'all',
        borderRadius: '50%',
    },
};
