import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import React, { FC } from 'react';
import { Div } from '..';

interface Props {
    name: string;
}

export const FaIcon: FC<Props> = ({ name }) => {
    return (
        <Div styles={[iconWrapperDefaultStyles]}>
            <Div
                styles={[iconDefaultStyles]}
                className={name}
                aria-hidden='true'
            />
        </Div>
    );
};

const iconWrapperDefaultStyles: ResponsiveStylesProp = {
    common: {
        lineHeight: 1,
        '[class*=" icon-"]::before': {
            cursor: 'pointer',
        },
    },
};

const iconDefaultStyles = {
    common: {
        display: 'block',
    },
};
