import React from 'react';
import { Label, Span } from 'client/widget-components/basicComponents';
import { Input } from 'client/widget-components/basicComponents/Input/Input';
import {
    mergeResponsiveStyles,
    nestResponsiveStyles,
    ResponsiveStylesProp,
} from '../../responsive-styles/responsiveStylesService';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';

export interface RadioButtonProps {
    label: string;
    labelDataGrab?: string;
    iconDataGrab?: string;
    value: string;
    selectedValue: string;
    onChange: (value: string) => void;
    labelStyles?: ResponsiveStylesProp;
    inputStyles?: ResponsiveStylesProp;
    inputIconStyles?: ResponsiveStylesProp;
}

const RadioButton = ({
    label,
    onChange,
    value,
    selectedValue,
    labelStyles,
    inputStyles,
    inputIconStyles,
    labelDataGrab,
    iconDataGrab,
    ...rest
}: RadioButtonProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };
    const checked = selectedValue === value;
    return (
        <Label styles={radioBtnWrapperDefaultStyles} {...rest}>
            <Input
                type='radio'
                onChange={handleChange}
                value={value}
                checked={checked}
                styles={{
                    [RuntimeMediaQuery.COMMON]: {
                        display: 'none',
                    },
                }}
            />
            <Span
                data-grab={iconDataGrab || 'radio-input'}
                styles={[
                    getRadioBtnStyles(checked),
                    inputStyles,
                    nestResponsiveStyles(
                        mergeResponsiveStyles(inputIconStyles),
                        {
                            innerSelector: '&:after',
                        }
                    ),
                ]}
            />
            <Text
                styles={[radioLabelDefaultStyles, labelStyles]}
                data-grab={labelDataGrab || 'radio-input-label'}
                tag={TextDomTag.span}
            >
                {label}
            </Text>
        </Label>
    );
};

const radioBtnWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        gap: '8px',
    },
};

const radioLabelDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '22px',
        textTransform: 'capitalize',
        color: '#313131',
    },
};

function getRadioBtnStyles(checked: boolean): ResponsiveStylesStructured[] {
    return [
        {
            [RuntimeMediaQuery.COMMON]: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '18px',
                height: '18px',
                border: '1px solid #ced6d9',
                borderRadius: '50%',
                boxSizing: 'border-box',
                backgroundColor: '#fff',
            },
        },
        nestResponsiveStyles(
            {
                [RuntimeMediaQuery.COMMON]: {
                    content: "''",
                    display: 'block',
                    opacity: checked ? 1 : 0,
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: '#30373a',
                },
            },
            {
                innerSelector: '&:after',
            }
        ),
    ];
}

export default RadioButton;
