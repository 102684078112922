import React, { CSSProperties, useMemo } from 'react';
import styled from 'styled-components';
import { OptionType } from 'client/widget-components/ecom/stores';
import { Dropdown } from 'client/widget-components';
import {
    DeprecatedResponsiveStyles,
    getBreakpointsStyle,
    SPECIFICITY_WRAP,
    upgradeStyles,
    DeprecatedWidgetStyles,
} from 'client/widget-components/responsive-styles/deprecated/styled-components-utils';
import isEmpty from 'lodash/isEmpty';
import { RuntimeWidget } from 'client/types';
import { context } from '@shared-services/context-service';
import { ecomObserver } from '@shared-services/ecom-service';
import { ProductState } from 'client/widget-components/ecom/stores/ProductState';

export interface RuntimeOptionsVariationsProps {
    productState: ProductState;
    _styles?: {
        wrapper?: CSSProperties;
        fields?: CSSProperties;
        title?: CSSProperties;
    };
    updateProduct: (optionKey: string, optionValue: OptionType) => void;
    dropdownPlaceholder?: string;
    editorEmptyMessage?: string;
}

const RuntimeOptionsVariations: RuntimeWidget<
    RuntimeOptionsVariationsProps
> = ({
    productState,
    editorEmptyMessage,
    updateProduct,
    dropdownPlaceholder,
    _styles,
}) => {
    const upgradedStyles = useMemo(() => {
        return upgradeStyles(_styles as DeprecatedWidgetStyles);
    }, [_styles]);

    const ovEntries = Object.entries(productState?.dropdownOptions || {});
    if (isEmpty(ovEntries)) {
        if (context.isEditor && editorEmptyMessage) {
            return <div>{editorEmptyMessage}</div>;
        }
        return <div />;
    }

    return (
        <RuntimeOptionsVariationsStyled
            styles={upgradedStyles.rules?.wrapper || {}}
            className='options-variations'
        >
            {ovEntries.map(([option, values]) => (
                <Dropdown
                    key={`option_${option}`}
                    className='dropdown-wrapper'
                    dropdownPlaceholder={dropdownPlaceholder}
                    isNativeComponent
                    defaultValue={productState.getSelectedField(option)}
                    options={values}
                    title={option}
                    styles={{
                        title: upgradedStyles.rules?.title,
                        fields: upgradedStyles.rules?.fields,
                    }}
                    onChange={(selectedVal) =>
                        updateProduct(option, selectedVal)
                    }
                />
            ))}
        </RuntimeOptionsVariationsStyled>
    );
};

const RuntimeOptionsVariationsStyled = styled.div<{
    styles?: DeprecatedResponsiveStyles | CSSProperties;
}>`
    ${SPECIFICITY_WRAP} {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        padding: 4px;
        gap: 18px;
        border: 0px solid;
        ${({ styles = {} }) => getBreakpointsStyle(styles)}
        &:empty {
            display: none;
        }
    }
`;

export default ecomObserver(RuntimeOptionsVariations);
