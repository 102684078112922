type ValueByType = {
    [FilterTypes.ONE_OF]: { [id: string]: string | number };
    [FilterTypes.BETWEEN]: {
        min: number;
        max: number;
        displayPrice: string;
    };
};

type SelectedValueByType = {
    [FilterTypes.ONE_OF]: Array<string | number>;
    [FilterTypes.BETWEEN]: { start: number; end: number };
};

interface FilterItem<T extends FilterTypes> extends FilterSortItemBaseProps {
    filtersData: ValueByType[T];
    selectedValues?: SelectedValueByType[T];
}
export interface FilterItemProps<T extends FilterTypes> {
    item: FilterItem<T>;
    onChange: (selected: FilterItem<T>['selectedValues']) => void;
    currentValues: FilterItem<T>['selectedValues'];
    type: T;
}
interface FilterSortItemBaseProps {
    fieldId: string;
    fieldDisplayName: string;
    disabled?: boolean;
}
export interface FilterSortExtensionData {
    collectionValue: string;
    sortableFields: Array<SortableField>;
    filterableFields: Array<FilterableField>;
    buttonText: string;
    isFilterSectionHidden: boolean;
    isSortSectionHidden: boolean;
    hasNativeStore: boolean;
    showWidgetMenuContent?: boolean;
}

export type SortDirection = 'asc' | 'desc';

export interface SortableField extends FilterSortItemBaseProps {
    sortDirection: SortDirection;
}

export interface FilterableField
    extends FilterSortItemBaseProps,
        FilterItem<FilterTypes> {
    filterType: FilterTypes;
}

export enum FilterTypes {
    ONE_OF = 'ONE_OF',
    BETWEEN = 'BETWEEN',
}

export interface FilterServiceData {
    sortBy: string | null;
    sortDirection: SortDirection | null;
    filters: { [filterName: string]: FilterableField };
}

export interface CollectionFilter {
    setCollectionSort: (
        sortBy: string,
        sortDirection: SortDirection | null
    ) => Promise<void>;
    setCollectionFilter: (
        filterId: string,
        filterValue: FilterableField
    ) => Promise<void>;
    onCollectionValueChange: (
        collectionId: string,
        value: (value: FilterServiceData) => void
    ) => Promise<() => void>;
    clearCollectionValues: () => void;
}

interface CollectionService {
    setCollectionSort: (
        collectionId: string,
        sortBy: string,
        sortDirection: SortDirection | null
    ) => Promise<void>;
    setCollectionFilter: (
        collectionId: string,
        filterName: string,
        filterValue: FilterableField
    ) => Promise<void>;
    getCollectionFilters: (collectionId: string) => Promise<CollectionFilter>;
    onCollectionValueChange: (
        collectionId: string,
        value: (value: FilterServiceData) => void
    ) => Promise<() => void>;
    clearCollectionValues: (collectionId: string) => void;
}

declare global {
    interface Window {
        runtime: {
            collectionsFilterService: CollectionService;
        };
    }
}
