import React from 'react';
import { FilterItemProps, FilterTypes } from '../utils/types';
import RangeFilterItem from './RangeFilterItem';
import SelectionFilterItem from './SelectionFilterItem';
import MenuItem from '../MenuItem';

function FilterItem(props: FilterItemProps<FilterTypes>) {
    return (
        <MenuItem title={props.item.fieldDisplayName}>
            {props.type === FilterTypes.BETWEEN ? (
                <RangeFilterItem
                    {...(props as FilterItemProps<FilterTypes.BETWEEN>)}
                />
            ) : (
                <SelectionFilterItem
                    {...(props as FilterItemProps<FilterTypes.ONE_OF>)}
                />
            )}
        </MenuItem>
    );
}

export default FilterItem;
