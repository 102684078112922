import { RangeSlider } from 'client/widget-components';
import React, { memo } from 'react';
import { FilterItemProps, FilterTypes } from '../utils/types';
import { useFilterSortStyles } from '../utils/filterSortContext';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

const DEFAULT_MIN_RANGE = 5;

function extractPriceDirection(displayPrice: string) {
    // extractPricePosition('$123.00'/'$123') => {direction: 'ltr', sign: '$'}
    // extractPricePosition('123.00€'/'123€') => {direction: 'rtl', sign: '€'}
    const result: { direction?: 'ltr' | 'rtl'; sign?: string } = {};
    const nonNumericSign = displayPrice.match(/[^\d.]/);
    if (nonNumericSign === null) {
        return result;
    }
    const signChar = nonNumericSign[0];
    const signCharPos = displayPrice.indexOf(signChar);
    if (signCharPos === 0) {
        result.direction = 'ltr';
    } else if (signCharPos === displayPrice.length - 1) {
        result.direction = 'rtl';
    } else {
        return result;
    }
    result.sign = signChar;
    return result;
}
function RangeFilterItem(props: FilterItemProps<FilterTypes.BETWEEN>) {
    const { item, currentValues, onChange } = props;
    const { min, max, displayPrice } = item.filtersData;
    const { sign, direction = 'ltr' } = extractPriceDirection(displayPrice);
    const { inputsStyle } = useFilterSortStyles();

    return (
        <RangeSlider
            min={min}
            max={max}
            currentValues={currentValues}
            onChange={onChange}
            minRange={DEFAULT_MIN_RANGE}
            sign={sign}
            styleSelector={`range-slider-${item.fieldId}`}
            labelsWrapperStyles={[valuesDefaultStyles, inputsStyle?.inputLabel]}
            labelStyles={getLabelStyle(direction)}
            trackStyles={inputsStyle?.slider}
            data-auto={`range-slider-${item.fieldId}`}
        />
    );
}

const valuesDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
    },
};
function getLabelStyle(direction: 'ltr' | 'rtl') {
    return {
        [RuntimeMediaQuery.COMMON]: {
            display: 'flex',
            flexDirection: direction === 'rtl' ? 'row' : 'row-reverse',
        },
    } as ResponsiveStylesStructured;
}

export default memo(RangeFilterItem);
