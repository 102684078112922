import React from 'react';
import { PaginationPropsBase } from '../PaginationProps';
import { Div } from 'client/widget-components/basicComponents';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { StyledButton } from 'client/widget-components/basicComponents/StyledButton';
import { FaIcon } from 'client/widget-components/basicComponents/FaIcon/FaIcon';
import { ArrowStyle } from 'client/widget-components/CommonModelTypes';

export interface ArrowPaginationProps extends PaginationPropsBase {
    goNext: () => void;
    goPrevious: () => void;
    arrowStyle?: ArrowStyle;
}

const Arrows: React.FC<ArrowPaginationProps> = ({
    styles,
    arrowStyle,
    goNext,
    goPrevious,
}) => {
    return (
        <Div
            styles={[containerDefaultStyle, styles?.container]}
            data-auto='pagination-arrows'
            data-grab='pagination-container'
        >
            <StyledButton
                styles={[defaultButtonStyle, styles?.buttonArrow]}
                data-grab='pagination-button-arrow'
                onClick={goPrevious}
                aria-label='back'
                data-auto={getNavigationButtonTestId('back')}
            >
                <FaIcon
                    {...getIconAttributesByArrowStyle({
                        pos: 'left',
                        arrowStyle,
                    })}
                />
            </StyledButton>
            <StyledButton
                styles={[defaultButtonStyle, styles?.buttonArrow]}
                data-grab='pagination-button-arrow'
                aria-label='next'
                data-auto={getNavigationButtonTestId('next')}
                onClick={goNext}
            >
                <FaIcon
                    {...getIconAttributesByArrowStyle({
                        pos: 'right',
                        arrowStyle,
                    })}
                />
            </StyledButton>
        </Div>
    );
};

function getNavigationButtonTestId(type: 'back' | 'next') {
    return `RuntimeSlider-navigation-${type}`;
}

interface GetIconAttributesByArrowStyle {
    pos: 'left' | 'right';
    arrowStyle?: ArrowStyle;
}

function getIconAttributesByArrowStyle({
    arrowStyle,
    pos,
}: GetIconAttributesByArrowStyle) {
    if (arrowStyle === 'arrow_thin') {
        return {
            name: pos === 'left' ? 'icon-angle-left' : 'icon-angle-right',
        };
    }

    if (arrowStyle === 'arrow_double') {
        return {
            name:
                pos === 'left'
                    ? 'icon-double-angle-left'
                    : 'icon-double-angle-right',
        };
    }

    return {
        name: pos === 'left' ? 'icon-chevron-left' : 'icon-chevron-right',
    } as const;
}

const containerDefaultStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        pointerEvents: 'none',
    },
};

const defaultButtonStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        backgroundColor: 'transparent',
        pointerEvents: 'auto',
        fontSize: 40,
        padding: 10,
    },
};

export default Arrows;
